<template>
  <el-header>
    <el-row class="background">
      <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18" :push="3">
        <el-menu
          router
          :default-active="this.$router.path"
          mode="horizontal"
         background-color="rgb(0 0 0 / 1)"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <el-col
            :xs="24"
            :sm="12"
            :md="6"
            :lg="6"
            :xl="6"
            class="hidden-sm-and-down"
          >
            <a href="/" target="_blank">
              <img
                class="imglog"
                :src="logoPic"
                alt="响应式模板(自适应移动设备)"
              />
            </a>
          </el-col>
          
          <el-col
            v-for="(item, index) in submenuList"
            :key="index"
            class="backgrounds" :xs="24" :sm="12" :md="3" :lg="3" :xl="3"
          >
            <!-- 一级菜单（没有任何子级菜单）-->
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
              <el-menu-item :index="item.path" v-if="!item.menuList" id="hovers">{{
                item.title
              }}</el-menu-item>

              <!-- 一级菜单（有子级菜单）-->
              <el-submenu :index="item.path" v-else :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <template slot="title">{{ item.title }}</template>

                <!-- 遍历二级菜单容器 -->
                <el-col  class="prolist" v-for="(i, index) in item.menuList" :key="index" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <!-- 判断二级菜单（没有三级菜单）-->
                  <el-menu-item :index="i.path" v-if="!i.list">{{i.title}}</el-menu-item>

                  <!-- 判断二级菜单（有三级菜单）-->
                  <el-submenu class="prolist-detail" :index="i.path" v-if="i.list" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <template slot="title">{{ i.title }}</template>
                    <el-menu-item
                      :index="j.path"
                      v-for="(j, index) in i.list"
                      :key="index"
                      >{{ j.title }}
                    </el-menu-item>
                  </el-submenu>
                </el-col>
              </el-submenu>
            </el-col>
          </el-col>
        </el-menu>
      </el-col>
    </el-row>
  </el-header>
</template>



<script>
import App from "../App.vue";
import "element-ui/lib/theme-chalk/display.css";
export default {
  data() {
    return {
      submenuList: [
        {
          title: "首页",
          path: "/",
        },
        {
          title: "公司简介",
          path: "/NewsList",
        },
        
        {
          title: "产品",
          path: "",
          menuList: [
            {
              title: "电网杆塔监测可视化平台",
              path: "/soultionList",
            },
            {
              title: "飞航视觉污染监测系统",
              path: "/productList",
            },
            {
              title:"智能激光销毁系统",
              path:"/productAiList"
            }
          ],
        },
        {
          title: "核心技术",
          path: "/CoreTechnology",
          menuList: [
            {
              title: "DEEP",
              path: "/technology/mobileSdk",
            },
            {
              title: "RPSP",
              path: "/technology/onBoard",
            },
            {
              title: "MMFP",
              path: "/technology/payload",
            },
            {
              title: "DNSS",
              path: "/technology/dnss",
            },
            {
              title: "MSML",
              path: "/technology/msml",
            },

          ],
        },

        {
          title: "联系我们",
          path: "/JobList",
        },
      ],
      logoPic: require("../assets/images/logo.png"),
    };
  },
  methods: {
    goHere() {
      switch (App) {
        case "/":
          break;
        case "/ProductList":
          this.$router.push({ path: "/ProductList" });
          break;
        case "/AboutList":
          this.$router.push({ path: "/AboutList" });
          break;
        case "/CoreTechnology":
          this.$router.push({ path: "/CoreTechnology" });
          break;
        case "/NewsList":
          this.$router.push({ path: "/NewsList" });
          break;
        case "/JobList":
          this.$router.push({ path: "/JobList" });
          break;
      }
    },
  },
};
</script>


<style>
.el-header {
  padding: 0 0px;
  height: 70px !important;
}
.background {
 background-color: rgb(0 0 0 / 1);
  text-align: center;
  z-index: 100;
  border-bottom: solid 1px #e6e6e6;
}
.imglog {
  max-height: 100%;
  max-width: 90%;
  height: 60px;
  margin-top: 5px;
}
.is-active{
background-color: rgb(0 0 0 / 50%);
}
.el-menu-item{
 height: 70px;
  line-height: 70px;
  font-size: 16px;
  /* background-color: rgb(0 0 0 / 10%); */
}
.el-submenu {
  height: 70px;
  line-height: 70px;
  font-size: 16px;
  /* background-color: rgb(0 0 0 / 10%); */
}
.el-submenu__title {
   height: 70px;
  line-height: 70px;
  font-size: 16px;
  /* background-color: rgb(0 0 0 / 10%); */
}
.el-submenu:hover{
  /* background-color: rgb(0 0 0 / 50%); */
  background-color: #fff;
}
.el-menu-item:hover {
  /* background-color: rgb(0 0 0 / 50%); */
  background-color: #fff;

}
.el-menu-item.is-active {
  background-color: rgb(0 0 0 / 50%);
}
.el-menu-demo {
  text-align: center;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  /* background-color: rgb(0 0 0 / 50%); */
  background-color: #fff;
}
.el-menu.el-menu--horizontal{
  border-bottom: aliceblue;
}
.el-submenu__title:focus, .el-submenu__title:hover{  
  outline: 0 !important;  
  color: #555 !important;  
  background: none !important;  
}  
.el-menu-item:hover{  
  outline: 0 !important;  
  color: #555 !important;  
    background-color: #fff !important;  
}  
/* .el-menu-item.is-active {  
  color: #fff !important;  
  background: #409EFF !important;  
}   */

.el-menu--popup-bottom-start{
  display: flex;
  flex-direction: column;
}
.el-menu--popup-bottom-start:last-child{
  position: absolute;
}
.prolist{
  width: 210px;
  text-align: center;
}
.prolist-detail{
  height: 36px;
  color: #000;
}
::v-deep .is-active {
  color: black;
  font-weight: bolder;
  border-bottom-color: black;
}

.active-text-color{
  color:#000;
}
</style>
