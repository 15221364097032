<template>
  <div id="app">
    <!-- 顶部 banner -->
    <top-bar></top-bar>
    <!-- 返回顶部组件 -->
    <!-- <back-top></back-top> -->
    <!-- 路由 -->
    <router-view></router-view>
    <!-- 底部 -->
    <lower-column></lower-column>
  </div>
</template>

<script>
import TopBar from './components/TopBar.vue';
import LowerColumn from './components/LowerColumn.vue';
// import BackTop from './components/BackTop.vue';


export default {

  components: {
    "top-bar": TopBar,
    "lower-column": LowerColumn,
    // "back-top": BackTop,
  }
}
</script>

<style>

</style>
